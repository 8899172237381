
.image-card__image{
    margin: 0 -1px;
    max-width: unset;
}
@media (min-width:576px){
}
@media (min-width:768px){    
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

