
.v-btn--underline.v-size--default{
    padding: 0 0 4px 0;
    min-width: 0;
    border-bottom: 1px solid var(--v-grey-base);
    font-size: 1.4rem;
}
.form-agree-box{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--v-grey-lighten4);
    padding: 20px;
    margin-top: 20px;
}
@media (min-width:576px){
}
@media (min-width:768px){
    .form-agree-box{
        padding: 30px;
    }
}
@media (min-width:1024px){
    .form-agree-box{
        border-width: 4px;
        margin-top: 40px;
    }
}
@media (min-width:1200px){
}

