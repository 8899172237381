
.service-banner {
    padding: 40px 0;
    color: #ffff;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .service-banner {
        height: 400px;
    }
}
@media (min-width: 1024px) {
    .service-banner {
        height: 600px;
    }
}
@media (min-width: 1200px) {
}
