
.history--group{
    .history:not(:last-child){
        margin-bottom: 40px !important;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .history--group{
        .history:not(:last-child){
            margin-bottom: 0 !important;
        }
        .history{
            .history__inner{
                padding-bottom: 60px;
                position: relative;
                &::after{
                    content: "";
                    display: block;
                    position: absolute;
                    width: 1px;
                    height: 100%;
                    top: 0;
                    background-color: var(--border-color);
                    z-index: 0;
                }
                .tit{
                    z-index: 2;
                    position: relative;
                    &::after{
                        content: "";
                        display: block;
                        position: absolute;
                        width: 17px;
                        height: 17px;
                        background-image: url(/images/icon/icon-dot.svg);
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }
        .history:first-child{
            .history__inner{
                padding-top: 60px;
            }
        }
        .history:last-child{
            .history__inner{
                padding-bottom: 0;
                &::after{
                    height: 32px;
                }
            }
        }
        .history:nth-child(2n-1){
            >[class*="col"]{
                margin-left: 50%;
            }
            .history__inner{
                &::after{
                    left: 0;
                }
                .tit{
                    &::after{
                        left: -20px;
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }
        .history:nth-child(2n){
            text-align: right;
            .history__inner{
                &::after{
                    right: -1px;
                }
                .tit{
                    &::after{
                        right: -21px;
                        transform: translate(50%, -50%);
                    }
                }
            }
        }
    }
}
@media (min-width:1024px){
    .history--group{
        .history:nth-child(2n-1){
            .history__inner{
                .tit{
                    &::after{
                        left: -40px;
                    }
                }
            }
        }
        .history:nth-child(2n){
            .history__inner{
                .tit{
                    &::after{
                        right: -41px;
                    }
                }
            }
        }
    }
}
@media (min-width:1200px){
}


.dot-texts .dot-text:not(:last-child) {
    margin-bottom: 6px;
}
@media (min-width: 1024px){
    .dot-texts .dot-text:not(:last-child) {
        margin-bottom: 12px;
    }
}
