
.icon-frame{
    width: 100%;
    height: 0;
    padding-top: 100%;
    position: relative;
    &__inner{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url(/images/icon/icon-frame.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    &-wrap{
        max-width: 180px;
        margin: 0 auto;
    }
    .v-image{
        max-width: 54px;
    }
    
}
@media (min-width:576px){
    .icon-frame{
        .v-image{
            max-width: 70px;
        }
        
    }
}
@media (min-width:768px){    
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

