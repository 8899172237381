
/* sub-visual */
.sub-visual {
    position: relative;
    display: flex;
    align-items: center;
    text-align: center;
    height: 200px;
    >.container{
        position: relative;
    }
}
.sub-visual__tit {
    font-size: 3rem;
    font-weight:700;
    color:#fff;
    >span{
        color: transparent;
        -webkit-text-stroke: #fff 1px;
    }
}
.sub-visual__bg {
    position: absolute;
    top:0;
    left: 50%;
    z-index: 0;
    -webkit-transform: translate(-50%,0); -ms-transform: translate(-50%,0); transform: translate(-50%,0);
    width: 100vw;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .sub-visual {
        height: 300px;
    }
    .sub-visual__tit {
        font-size: 4rem;
    }
}
@media (min-width: 1024px) {
    .sub-visual {
        height: 400px;
        padding-top: 0;
    }
    .sub-visual__tit {
        font-size: 6.8rem;
    }
}
@media (min-width: 1200px) {
}
