
.section--location{
    background-image: url(/images/sub/about/location/bg.svg);
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;
}
.icon-wrap{
    min-width: 48px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 1px solid var(--v-grey-lighten3);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
}
iframe{
    width: 100vw;
    height: 550px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    display: block;
}
.loctaion-info{
    margin-top: -102px;
}
@media (min-width:576px){
}
@media (min-width:768px){
    .loctaion-info{
        margin-top: -54px;
    }
}
@media (min-width:1024px){
    .icon-wrap{
        min-width: 60px;
        width: 60px;
        height: 60px;
    }
    .loctaion-info{
        margin-top: -70px;
    }
}
@media (min-width:1200px){
}
