
.icon-card__icon{
    max-width: 40px;
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .icon-card__icon{
        max-width: 48px;
    }
}
@media (min-width:1200px){
}

