
.v-tabs--primary{
    .v-tab{
        transition: 0.15s ease-out;
        white-space: nowrap;
        flex: 1 1 0;
        height: 40px;
        font-size: 1.4rem;
        color: #111 !important;
        background-color: var(--v-grey-lighten5);
        font-family: "Play","Pretendard","Nanum Gothic","Apple SD Gothic Neo",Helvetica,Arial,sans-serif;
        &.v-tab--active{
            background-color: var(--v-primary-base);
            color: #fff !important;
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .v-tabs--primary{
        .v-tab{
            height: 48px;
            font-size: 1.6rem;
        }
        .v-tab:hover{
            background-color: var(--v-primary-base);
            color: #fff !important;
        }
    }
}
@media (min-width:1200px){
}

