
.section{
    padding: 40px 0;
    &--first{
        padding-top: 80px;
    }
    &--last{
        padding-bottom: 80px;
    }
}
.page-section{
    padding-top: 40px;
    &--first{
        padding-top: 0 !important;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .section{
        padding: 60px 0;
        &--first{
            padding-top: 120px;
        }
        &--last{
            padding-bottom: 120px;
        }
    }
    .page-section{
        padding-top: 60px;
    }
}
@media (min-width:1024px){
    .section{
        padding: 80px 0;
        &--first{
            padding-top: 160px;
        }
        &--last{
            padding-bottom: 160px;
        }
    }
    .page-section{
        padding-top: 80px;
    }
}
@media (min-width:1200px){
}
